/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import { Button, Spinner } from 'react-bootstrap';
import logo from '../lilly_logo.png';
import { Buffer } from 'buffer';
import {
  getUserDetails,
  getSingleFormRequest,
  getAllStrategicInitiativeData,
  getAllCountriesData,
  getAllBrandsData,
  getAllIndicationsData,
  getAllTacticTypesData,
  getAllPrimaryCategoryData,
  getAllPrimarySubCategoryData,
  getAllSecondaryCategoryData,
  getAllSecondarySubCategoryData,
  createRequest,
  updateRequest,
  cancelRequest,
  getVVPMDocumentsByFilters,
  updateGoNogoRequest,
  generateRequestExcel,
  getVendorList
} from '../constants/apiCalls';
import { loginRequest } from '../constants/authConfig';
import {
  roles,
  formSubmitterDetails,
  assetLanguageData,
  approveBudgetData,
  currencyTypeData,
  assetUsageData,
  customerSegmentaionData,
  contentTypeData,
  channelData,
  assetTacticReuseData,
  assetObjectiveData,
  categoryData
} from "../constants/enum";
import Breadcrumbs from "../components/Breadcrumbs";
import Input from "../components/Form/Input";
import FormSelect from "../components/Form/Select";
import FormDatePicker from "../components/Form/DatePicker";
import makeAnimated from "react-select/animated";
import VVPMModal from "../components/VvpmModal";
import Modal from "../components/cancelModal";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import '../styles/intakeform.css';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Renders information about the user obtained from MS Graph 
 * @param props
 */
const IntakeForm = (props) => {
  const { instance, accounts } = useMsal();
  const animatedComponents = makeAnimated();
  const [userDetails, setUserDetails] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [userRole, setUserRole] = useState('');
  const [downloadpdf, setDownloadPdf] = useState(false)
  // All states to display error messages
  const [errorMessageName, setErrorMessageName] = useState(null);
  const [errorMessageCategory, setErrorMessageCategory] = useState(null);
  const [errorMessageBudgetApproval, setErrorMessageBudgetApproval] = useState(null);
  const [errorMessageBudgetCurrency, setErrorMessageBudgetCurrency] = useState(null);
  const [errorMessageBudget, setErrorMessageBudget] = useState(null);
  const [errorMessageAssetUsage, setErrorMessageAssetUsage] = useState(null);
  const [errorMessageIndication, setErrorMessageIndication] = useState(null);
  const [errorMessageBrandBudgetTactic, setErrorMessageBrandBudgetTactic] = useState(null);
  const [errorMessageCountry, setErrorMessageCountry] = useState(null);
  const [errorMessageContentType, setErrorMessageContentType] = useState(null);
  const [errorMessageChannel, setErrorMessageChannel] = useState(null);
  const [errorMessageVVPMMaterialNumber, setErrorMessageVVPMMaterialNumber] = useState(null);
  const [errorMessageTacticType, setErrorMessageTacticType] = useState(null);
  const [errorMessageAssetLanguage, setErrorMessageAssetLanguage] = useState(null);
  const [errorMessageAssetDueDate, setErrorMessageAssetDueDate] = useState(null);
  const [errorMessagePrimaryCategory, setErrorMessagePrimaryCategory] = useState(null);
  const [errorMessagePrimarySubCategory, setErrorMessagePrimarySubCategory] = useState(null);
  const [errorMessageStrategicInitiative, setErrorMessageStrategicInitiative] = useState(null);
  const [errorMessageActualCost, setErrorMessageActualCost] = useState(null);
  const [errorMessageActualCurrency, setErrorMessageActualCurrency] = useState(null);
  const [selectedBrandVVPMID, setSelectedBrandVVPMID] = useState([]);

  // All states that are used in form
  const [selectedForm, setSelectedForm] = useState('');
  const [vendorList, setVendorList] = useState([]);
  const [formStatus, setFormStatus] = useState(0);
  const [formStatusName, setFormStatusName] = useState('New');
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [requesterName, setRequesterName] = useState('');
  const [category, setCategory] = useState({ label: '', value: '' });
  const [budgetApproval, setBudgetApproval] = useState({ label: '', value: '' })
  const [budgetCurrency, setBudgetCurrency] = useState({ label: '', value: '' })
  const [budget, setBudget] = useState('');
  const [assetUsage, setAssetUsage] = useState({ label: '', value: '' });
  const [indication, setIndication] = useState([]);
  const [brandBugetTactic, setBrandBudgetTactic] = useState([]);
  const [country, setCountry] = useState({ label: '', value: '' });
  const [customerSegmentaion, setCustomerSegmentation] = useState([]);
  const [contentType, setContentType] = useState({ label: '', value: '' });
  const [channel, setChannel] = useState([]);
  const [assetTacticReuse, setAssetTacticReuse] = useState({ label: '', value: '' });
  const [vvpmMaterialNumber, setVVPMMaterialNumber] = useState('');
  const [tacticType, setTacticType] = useState({ label: '', value: '' });
  const [assetLanguage, setAssetLanguage] = useState({ label: '', value: '' });
  const [assetObjective, setAssetObjective] = useState([]);
  const [assetDueDate, setAssetDueDate] = useState();
  const [studiesIncluded, setStudiesInclided] = useState('')
  const [actualAssetCurrency, setActualAssetCurrency] = useState({ label: '', value: '' });
  const [actualAssetCost, setActualAssetCost] = useState('')
  const [searchKeyword, setSearchKeyword] = useState('');
  const [requestType, setRequestType] = useState('');
  const [strategicInitiative, setStrageticInitiative] = useState({ label: '', value: '' });
  const [primaryCategory, setPrimaryCategory] = useState({ label: '', value: '' });
  const [primarySubCategory, setPrimarySubCategory] = useState({ label: '', value: '' });
  const [secondaryCategory, setSecondaryCategory] = useState({ label: '', value: '' });
  const [secondarySubCategory, setSecondarySubCategory] = useState({ label: '', value: '' });
  const [speakerName, setSpeakerName] = useState('');
  const [vvpmDocs, setvvpmDocs] = useState([]);
  const [isEwizard, setIsEwizard] = useState('');
  const [country_v, setCountry_v] = useState('');
  const [countryResponse, setCountryResponse] = useState([]);
  const [brandList, setbrandList] = useState([]);
  // All states that will contain Dropdown Data
  const [categoryDropdownData, setCategoryDropdownData] = useState([]);
  const [budgetApprovalDropdownData, setBudgetApprovalDropdownData] = useState([]);
  const [budgetCurrencyDropdownData, setBudgetCurrentDropdownData] = useState([]);
  const [assetUsageDropdownData, setAssetUsageDropdownData] = useState([]);
  const [indicationDropdownData, setIndicationDropdownData] = useState([]);
  const [brandBudgetTacticDropdownData, setBrandBudgetTacticDropdownData] = useState([]);
  const [tempbrandData, setTempBrandData] = useState([]);
  const [countryDropdownData, setCountryDropdownData] = useState([]);
  const [customerSegemntationDropdownData, setCustomerSegemntationDropdownData] = useState([]);
  const [contentTypeDropdownData, setContentTypeDropdownData] = useState([]);
  const [channelDropdownData, setChannelDropdownData] = useState([]);
  const [assetTacticReuseDropdownData, setAssetTacticReuseDropdownData] = useState([]);
  const [tacticTypeDropdownData, setTacticTypeDropdownData] = useState([]);
  const [assetLanguageDropdownData, setAssetLangageDropdownData] = useState([]);
  const [assetObjectiveDropdownData, setAssetObjectiveDropdownData] = useState([]);
  const [actucalCurrencyDropdownData, setActucalCurrencyDropdownData] = useState([]);
  const [strategicInitiativeDropdownData, setStrategicInitiativeDropdownData] = useState([]);
  const [primaryCategoryDropdownData, setPrimaryCategoryDropdownData] = useState([]);
  const [primarySubCategoryDropdownData, setPrimarySubCategoryDropdownData] = useState([]);
  const [secondaryCategoryDropdownData, setSecondaryCategoryDropdownData] = useState([]);
  const [secondarySubCategoryDropdownData, setSecondarySubCategoryDropdownData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();
  const [givenComment, setGivenomment] = useState();

  // other states for modal
  const [vvpmModal, setVvpmModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [nogoPopout, setNogoPopout] = useState(false);
  const [requestNumber, setRequestNumber] = useState('');
  const [createRequestData, setCreateRequestData] = useState();
  const [formDataById, setFromDataByID] = useState();
  const [saveMessage, setSaveMessage] = useState(false);
  const [exceptionMessage, setExceptionMessage] = useState(false);
  const [cancelMessage, setCancelMessage] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(false);
  const [vendorData, setVendorData] = useState('');
  const [vvpmDocsNoGo, setVvpmDocsNoGo] = useState('');
  const [vvpmNoGoFlag, setVvpmNoGoFlag] = useState('No');
  const [vendorNoGo, setVendorNoGo] = useState();


  let navigate = useNavigate();
  // functions
  function RequestUserDetails() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        // add id token to localstorage for postman
        localStorage.setItem('id_token', response.idToken);
        getUserDetails(response.idToken, response.idToken)
          .then((data) => {
            if (data.data != null) {
              let userData = data.data;
              setUserDetails(userData);
              setCurrentUser(userData);
              setUserRole(roles[userData.role - 1]);
              setUserName(userData.user_name);
              setUserId(userData.user_id);
            } else {
              navigate('/accessdenied')
            }
          }).catch(err => console.log(err));
      })
      .catch(err => console.log(err))
  }
  /**
   * Fetches the form details based on form_id on click of request number
   * @param {*} form_id is used for fetching details of specific form
   * @returns {Array}
   */
  function RequestFormDetails(form_id) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        // add id token to localstorage for postman
        localStorage.setItem('id_token', response.idToken);
        setSpinner(true);
        getSingleFormRequest(response.idToken, { request_id: form_id })
          .then(response => {
            if (response.statusCode == 200) {
              setSpinner(false);
              let formData = response.data;
              console.log('getsingle form data', formData);
              // null handling
              setFromDataByID(formData);
              setIsEwizard(formData.ewizard);
              setRequestNumber(formData.request_number);
              setFormStatusName(formData.status_name);
              setFormStatus(formData.status)
              setUserName(formData.created_by_name);
              setUserId(formData.created_by);
              setRequesterName(formData.requested_for);
              if (formData.category_id[0]) {
                setCategory({ label: categoryData.find(x => x.value == formData.category_id[0]).label, value: formData.category_id[0] })
              }
              if (formData.is_budget_approve == true) {
                setBudgetApproval({ label: approveBudgetData[0].label, value: approveBudgetData[0].value })
              }
              if (formData.is_budget_approve == false) {
                setBudgetApproval({ label: approveBudgetData[1].label, value: approveBudgetData[1].value })
              }
              if (formData.budget_currency) {
                setBudgetCurrency({ label: currencyTypeData.find(x => x.value == formData.budget_currency).label, value: formData.budget_currency })
              }
              setBudget(formData.budget ? formData.budget % 1 !== 0 ? formData.budget.toFixed(4) : formData.budget : formData.budget);
              if (formData.content_type) {
                setContentType({ label: contentTypeData.find(x => x.value == formData.content_type).label, value: formData.content_type });
                // setBrandBudgetTactic(formData.brand.data.map(x => ({ label: x.brand_name, value: x.brand_id, priority: x.priority, vvpm_id: x.vvpm_id })));
              }
              if (formData.content_type == '1') {
                let tempDataBrand =  [
                  {label: 'Emgality', value: 1, vvpm_id: '00P000000001301'},
                  {label: 'Olumiant', value: 2, vvpm_id: '00P000000000Q01'},
                  {label: 'Taltz', value: 3, vvpm_id: '00P000000000914'}
                ]
                console.log('branddata', tempDataBrand)
                let tempdata = [];
                tempDataBrand.forEach(x => {
                  if (x.label != 'Emgality') {
                    tempdata.push(x)
                  }
                })
                console.log('changing priority', tempdata, tempDataBrand)

                setBrandBudgetTacticDropdownData(tempdata)
              } else if (formData.content_type == '2'){
                let tempDataBrand =  [
                  {label: 'Emgality', value: 1, vvpm_id: '00P000000001301'},
                  {label: 'Olumiant', value: 2, vvpm_id: '00P000000000Q01'},
                  {label: 'Taltz', value: 3, vvpm_id: '00P000000000914'}
                ]
                console.log('branddata', tempDataBrand)
                let tempdata = [];
                tempDataBrand.forEach(x => {
                  if (x.label == 'Emgality') {
                    tempdata.push(x)
                  }
                })
                console.log('changing non-prirority', tempdata, tempDataBrand)
                
                setBrandBudgetTacticDropdownData(tempdata)
              }
              setSelectedVendor(formData.vendor_id);
              setGivenomment(formData.comment);
              if (formData.asset_use) {
                setAssetUsage({ label: assetUsageData.find(x => x.value == formData.asset_use).label, value: formData.asset_use });
              }
              setIndication(formData.indicationArray.data.map(x => ({ label: x.indication_name, value: x.indication_id, vvpm_id: x.vvpm_id })));
              setBrandBudgetTactic(formData.brand.data.map(x => ({ label: x.brand_name, value: x.brand_id, priority: x.priority, vvpm_id: x.vvpm_id })));
              setCountry({ label: formData.country_name, value: formData.country_id });
              setCustomerSegmentation(formData.customer_segementation.map(x =>
                customerSegmentaionData.find(y => y.value == x)));
              setChannel(formData.channel.map(x =>
                channelData.find(y => y.value == x)));
              if (formData.tactic_usability) {
                setAssetTacticReuse({ label: assetTacticReuseData.find(x => x.value == formData.tactic_usability).label, value: formData.tactic_usability });
              }
              setVVPMMaterialNumber(formData.vvpm_material_number);
              setTacticType({ label: formData.tactic_type_name, value: formData.tactic_type });
              if (formData.asset_language) {
                setAssetLanguage({ label: assetLanguageData.find(x => x.value == formData.asset_language).label, value: formData.asset_language });
              }
              if (formData.asset_objective) {
                setAssetObjective(formData.asset_objective.map(x => assetObjectiveData.find(y => y.value == x)));
              }
              if (formData.asset_due_date) {
                setAssetDueDate(new Date(formData.asset_due_date));
              }
              setStudiesInclided(formData.studies_included);
              if (formData.asset_actual_cost_currency) {
                setActualAssetCurrency({ label: currencyTypeData.find(x => x.value == formData.asset_actual_cost_currency).label, value: formData.asset_actual_cost_currency });
              }
              setActualAssetCost(formData.asset_actual_cost);
              setSearchKeyword(formData.keywords);
              setStrageticInitiative({ label: formData.strategic_initiative_name, value: formData.strategic_initiative });
              setPrimaryCategory({ label: formData.primary_category_name, value: formData.primary_category_id, vvpm_id: formData?.primary_category_vvpm_id });
              setPrimarySubCategory({ label: formData.primary_sub_category_name, value: formData.primary_sub_category_id });
              setSecondaryCategory({ label: formData.secondary_category_name, value: formData.secondary_category_id, vvpm_id: formData.secondary_category_vvpm_id });
              setSecondarySubCategory({ label: formData.secondary_sub_category_name, value: formData.secondary_sub_category_id });
              setSpeakerName(formData.speaker_name);
              setvvpmDocs((formData.vvpm_documents).startsWith('[{') ? JSON.parse(formData.vvpm_documents) : "");
            }
            else {
              setSpinner(true);
            }
          })

          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }
  /**
   * Fetches the beared token and gets the dropdown data from API
   * Sets the dropdown data from enum
   * @returns {Array}
   */
  function RequestDropdownData() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        RequestStrategicInitiativeDropdown(response.idToken);
        RequestBrandsDropdown(response.idToken);
        RequestVendorData(response.idToken);
        RequestIndicationsDropdown(response.idToken);
        RequestTacticTypesDropdown(response.idToken);
        RequestPrimaryCategoryDropdown(response.idToken);
        RequestPrimarySubCategoryDropdown(response.idToken);
        RequestSecondayCategoryDropdown(response.idToken);
        RequestSecondarySubCategoryDropdown(response.idToken);
        RequestCountriesDropdown(response.idToken);
        //set all Other dropdowns
        setCategoryDropdownData(categoryData);
        setBudgetApprovalDropdownData(approveBudgetData);
        setBudgetCurrentDropdownData(currencyTypeData);
        setAssetUsageDropdownData(assetUsageData);
        setCustomerSegemntationDropdownData(customerSegmentaionData);
        setContentTypeDropdownData(contentTypeData);
        setChannelDropdownData(channelData);
        setAssetTacticReuseDropdownData(assetTacticReuseData);
        setAssetLangageDropdownData(assetLanguageData);
        setAssetObjectiveDropdownData(assetObjectiveData);
        setActucalCurrencyDropdownData(currencyTypeData);
      })
      .catch(err => console.log(err))
  }
  /**
   * Fetches the form details based on form_id on click of request number
   * @param {*} form_id is used for fetching details of specific form
   * @returns {Array}
   */
  function RequestStrategicInitiativeDropdown(accessToken) {
    getAllStrategicInitiativeData(accessToken)
      .then(response => setStrategicInitiativeDropdownData(response.data))
      .catch(err => console.log(err))
  }
  /**
   * Fetches the Vendor details based on access token from API
   * @param {*} accessToken is used for authentication
   * @returns {Array}
   */
  function RequestVendorData(accessToken) {
    getVendorList(accessToken)
      .then(response => {
        if (response) {
          if (response.statusCode === "200") {

            setVendorList(response.data);

          }
          else {
            setSpinner(true);
          }
        }
      })
      .catch(err => console.log(err))
  }

  const handleCountryChange = (event) => {
    setIsEwizard(countryResponse.find(cnty => cnty.country_id === event.value).ewizard)
    setCountry_v(countryResponse.find(cnty => cnty.country_id === event.value).country_v)
    setCountry({
      label: event.label,
      value: event?.value,
    })
  }

  function RequestBrandsDropdown(accessToken) {
    getAllBrandsData(accessToken)
      .then(response => {
        if (response) {
          if (response.statusCode == "200") {
            setbrandList(response.data)
            let tempData = response.data.map((x) => ({
              label: x.brand_name,
              value: x.brand_id,
              vvpm_id: x.vvpm_id
            }));
            console.log('tempData', tempData)
            setTempBrandData(tempData)
          }
          else {
            setSpinner(true);
          }
        }
      })
      .catch(err => console.log(err))
  }
  function RequestIndicationsDropdown(accessToken) {
    getAllIndicationsData(accessToken)
      .then(response => setIndicationDropdownData(response.data))
      .catch(err => console.log(err))
  }
  function RequestTacticTypesDropdown(accessToken) {
    getAllTacticTypesData(accessToken)
      .then(response => setTacticTypeDropdownData(response.data))
      .catch(err => console.log(err))
  }
  function RequestPrimaryCategoryDropdown(accessToken) {
    getAllPrimaryCategoryData(accessToken)
      .then(response => {
        if (response) {
          if (response.statusCode == "200") {
            let tempData = response.data.map((x) => ({
              label: x.category_name,
              value: x.category_id,
              vvpm_id: x.vvpm_id
            }));
            setPrimaryCategoryDropdownData(tempData);
          }
          else {
            setSpinner(true);
          }
        }
      }).catch(err => console.log(err))
  }
  function RequestPrimarySubCategoryDropdown(accessToken) {
    getAllPrimarySubCategoryData(accessToken)
      .then(response => {
        if (response) {
          if (response.statusCode == "200") {
            let tempData = response.data.map((x) => ({
              label: x.sub_category_name,
              value: x.sub_category_id,
            }));

            setPrimarySubCategoryDropdownData(tempData);
          }
          else {
            setSpinner(true);
          }
        }
      }).catch(err => console.log(err))
  }
  function RequestSecondayCategoryDropdown(accessToken) {
    getAllSecondaryCategoryData(accessToken)
      .then(response => {
        if (response) {
          if (response.statusCode == "200") {
            let tempData = response.data.map((x) => ({
              label: x.category_name,
              value: x.category_id,
            }));
            setSecondaryCategoryDropdownData(tempData);
          }
          else {
            setSpinner(true);
          }
        }
      }).catch(err => console.log(err))
  }
  function RequestSecondarySubCategoryDropdown(accessToken) {
    getAllSecondarySubCategoryData(accessToken)
      .then(response => {
        if (response) {
          if (response.statusCode == "200") {
            let tempData = response.data.map((x) => ({
              label: x.sub_category_name,
              value: x.sub_category_id,
            }));
            setSecondarySubCategoryDropdownData(tempData);
          }
          else {
            setSpinner(true);
          }
        }
      }).catch(err => console.log(err))
  }
  function RequestCountriesDropdown(accessToken) {
    getAllCountriesData(accessToken)
      .then((response) => {
        if (response) {
          if (response.statusCode == "200") {
            let tempData = response.data.map((x) => ({
              label: x.country_name,
              value: x.country_id,
            }));
            setCountryDropdownData(tempData);
            setCountryResponse(response.data)
          }
          else {
            setSpinner(true);
          }
        }
      })
      .catch(err => console.log(err))
  }
  /**
   * Saves the form details into database
   * @param {*} requestValue is used to differentiate between save and update form
   * @returns {Array}
   */
  function SendData(requestValue) {
    let data = {
      requester_name: userDetails.user_id,
      requested_for: requesterName,
      asset_id: assetUsage.value,
      is_budget_approve: budgetApproval.value,
      budget: budget,
      budget_currency: budgetCurrency.value,
      asset_use: assetUsage.value,
      indication_id: indication.map(x => Number(x.value)),
      brand_id: brandBugetTactic.map(x => Number(x.value)),
      country_id: country.value,
      category_id: [Number(category.value)],
      customer_segementation: customerSegmentaion.map(x => Number(x.value)),
      content_type: contentType.value,
      channel: channel.map(x => Number(x.value)),
      tactic_usability: assetTacticReuse.value,
      vvpm_material_number: vvpmMaterialNumber,
      tactic_type: tacticType.value,
      asset_language: assetLanguage.value,
      asset_objective: assetObjective.map(x => Number(x.value)),
      asset_due_date: assetDueDate !== undefined ? moment(new Date(assetDueDate).toJSON()).format('L') : assetDueDate,
      asset_actual_cost: actualAssetCost,
      asset_actual_cost_currency: actualAssetCurrency.value,
      keywords: searchKeyword,
      primary_category_id: primaryCategory.value,
      secondary_category_id: secondaryCategory.value,
      primary_sub_category_id: primarySubCategory.value,
      secondary_sub_category_id: secondarySubCategory.value,
      studies_included: studiesIncluded,
      strategic_initiative: strategicInitiative.value,
      speaker_name: speakerName,
      vvpm_documents: "",
      status: formStatus == 0 ? '1' : formStatus,
      created_by: userDetails.user_id,
      updated_by: userDetails.user_id,
    }
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        if (!createRequestData?.request_id) {
          createRequest(response.idToken, data)
            .then(reqData => {
              if (reqData.statusCode == "200") {
                if (requestValue == "Save" && (formStatusName != "Submitted" || formStatusName != "Exception")) {
                  toast.success("Saved Successfully!!!");
                  setSaveMessage(true);
                  setTimeout(() => {
                    navigate("/");
                  }, 1000);
                } else {
                  setCreateRequestData(reqData.data)
                  setVvpmModal(true);
                }
              } else {
                toast.error("Unable to save due to server error");
              }
            })
            .catch(err => console.log(err))
        } else {
          if (requestValue == "Save" && (formStatusName != "Submitted" || formStatusName != "Exception")) {
            SendUpdateData('Save');
          } else {
            setVvpmModal(true);
          }
        }
      })
      .catch(err => console.log(err))
  }
  /**
   * Cancels form based upon the form id
   * @param {*} form_id is used for fetching details of specific form to be
   */
  const handleCancel = () => {
    let form_id = parseInt(window.location.pathname.split("/").pop(), 10);
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        cancelRequest(response.idToken, { request_id: form_id })
          .then(response => {
            if (response) {
              if (response.statusCode == "200") {
                toast.success("Form Cancelled!");
                setCancelMessage(true);
                setTimeout(() => {
                  navigate("/");
                }, 1000);
              } else {
                toast.error("Unable to cancel due to server error");
              }
            }
          }).catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }

  const handleCancelRequest = () => {
    setConfirmationModal(true);
  }

  const handleIndication = (event) => {
    let tempData = event.map((x) => ({
      label: x.label,
      value: x.value,
      vvpm_id: x.vvpm_id
    }));
    setIndication(tempData);
  };
  const handleBrandBudgetTactic = (event) => {
    let tempData = event.map((x) => ({
      label: x.label,
      value: x.value,
      vvpm_id: x.vvpm_id
    }));

    setSelectedBrandVVPMID((brandList.filter(v => tempData.map(b => b.value).includes(v.brand_id))).map(v1 => v1.vvpm_id))
    setBrandBudgetTactic(tempData);
  }
  const handleCustomerSegmentation = (event) => {
    let tempData = event.map((x) => ({
      label: x.label,
      value: x.value,
    }));
    setCustomerSegmentation(tempData);
  };
  const handleChannel = (event) => {
    let tempData = event.map((x) => ({
      label: x.label,
      value: x.value,
    }));
    setChannel(tempData)
  }
  const handleAssetObjective = (event) => {
    let tempData = event.map((x) => ({
      label: x.label,
      value: x.value,
    }));
    setAssetObjective(tempData);
  }
  const handleAssetDueDate = (date) => {
    return setAssetDueDate(date);
  };

  const resetErrorStates = () => {
    if (userName != '' || formStatusName == "Cancelled") {
      setErrorMessageName('');
    }
    if (category.value != '' || formStatusName == "Cancelled") {
      setErrorMessageCategory('');
    }
    if (budgetApproval.value != '' || formStatusName == "Cancelled") {
      setErrorMessageBudgetApproval('');
    }
    if (budgetCurrency.value != '' || formStatusName == "Cancelled") {
      setErrorMessageBudgetCurrency('');
    }
    if (budget != '' || formStatusName == "Cancelled") {
      setErrorMessageBudget('');
    }
    if (assetUsage.value != '' || formStatusName == "Cancelled") {
      setErrorMessageAssetUsage('');
    }
    if (indication != '' || formStatusName == "Cancelled") {
      setErrorMessageIndication('');
    }
    if (brandBugetTactic != '' || formStatusName == "Cancelled") {
      setErrorMessageBrandBudgetTactic('');
    }
    if (country.value != '' || formStatusName == "Cancelled") {
      setErrorMessageCountry('');
    }
    if (contentType.value != '' || formStatusName == "Cancelled") {
      setErrorMessageContentType('')
    }
    if (channel != '' || formStatusName == "Cancelled") {
      setErrorMessageChannel('');
    }
    if (assetTacticReuse.value != '2' && vvpmMaterialNumber != '' || formStatusName == "Cancelled") {
      setErrorMessageVVPMMaterialNumber('');
    }
    if (tacticType.value != '' || formStatusName == "Cancelled") {
      setErrorMessageTacticType('')
    }
    if (assetLanguage.value != '' || formStatusName == "Cancelled") {
      setErrorMessageAssetLanguage('')
    }
    if (assetDueDate != '' || assetDueDate != undefined || formStatusName == "Cancelled") {
      setErrorMessageAssetDueDate('')
    }
    if (actualAssetCost?.value != '' || formStatusName == "Cancelled") {
      setErrorMessageActualCost('')
    }
    if (actualAssetCurrency.value != '' || formStatusName == "Cancelled") {
      setErrorMessageActualCurrency('')
    }
    if (primaryCategory.value != '' || formStatusName == "Cancelled") {
      setErrorMessagePrimaryCategory('')
    }
    if (primarySubCategory.value != '' || formStatusName == "Cancelled") {
      setErrorMessagePrimarySubCategory('')
    }
    if (strategicInitiative.value != '' || formStatusName == "Cancelled") {
      setErrorMessageStrategicInitiative('');
    }
  }

  // Validation for mandetory form fields
  const handleValidate = () => {
    resetErrorStates();
    let err = '';
    if (userName == '') {
      setErrorMessageName('Name cannot be empty');
      err += 'Name cannot be empty'
    }
    if (category.value == '') {
      setErrorMessageCategory('Category field cannot be empty');
      err += 'Category field cannot be empty'
    }
    if (budgetApproval.value == '') {
      setErrorMessageBudgetApproval('Is the budget approved to develop the asset? field cannot be empty');
      err += 'Is the budget approved to develop the asset? field cannot be empty'
    }
    if (budgetCurrency.value == '') {
      err += 'Budget currency field cannot be empty'
      setErrorMessageBudgetCurrency('Budget currency field cannot be empty');
    }
    if (budget == '' && budget > 0) {
      setErrorMessageBudget('Budget field cannot be empty');
      err += 'Budget field cannot be empty'
    }
    if (!String(budget).match('[-+]?([0-9]*\.[0-9]+|[0-9]+)')) {
      setErrorMessageBudget('Enter budget in number format only');
      err += 'Enter budget in number format only'
    }
    if (budget < 0) {
      setErrorMessageBudget('Please enter positive numbers only');
      err += 'Please enter positive numbers only'
    }
    if (assetUsage.value == '') {
      setErrorMessageAssetUsage('Where is the asset going to be used? field cannot be empty');
      err += 'Where is the asset going to be used? field cannot be empty'
    }
    if (brandBugetTactic == '') {
      setErrorMessageBrandBudgetTactic('Which brand budget is the tactic approved cannot be empty');
      err += 'Which brand budget is the tactic approved cannot be empty'
    }
    if (country.value == '') {
      setErrorMessageCountry('Country the asset is requested for field cannot be empty');
      err += 'Country the asset is requested for field cannot be empty'
    }
    if (contentType.value == '') {
      setErrorMessageContentType('content type field cannot be empty')
      err += 'Content type field cannot be empty'
    }
    if (channel == '') {
      setErrorMessageChannel('Channel(s) the asset is produced for/Tactic type field cannot be empty');
      err += 'Channel(s) the asset is produced for/Tactic type field cannot be empty'
    }
    if (tacticType.value == '' || !tacticType.value) {
      setErrorMessageTacticType('Type of asset/Tactic Type field cannot be empty')
      err += 'Type of asset/Tactic Type field cannot be empty'
    }
    if (assetLanguage.value == '') {
      setErrorMessageAssetLanguage('Language of the Asset field cannot be empty')
      err += 'Language of the Asset field cannot be empty'
    }
    if (assetDueDate == undefined) {
      setErrorMessageAssetDueDate('Due date for the asset field cannot be empty')
      err += 'Due date for the asset field cannot be empty'
    }
    if (actualAssetCost != '' && actualAssetCost != null && (!String(actualAssetCost).match('[-+]?([0-9]*\.[0-9]+|[0-9]+)'))) {
      setErrorMessageActualCost('Actual asset cost should be in number format only')
      err += 'Actual asset cost should be in number format only'
    }
    if (actualAssetCurrency.value == '' && actualAssetCost != '' && actualAssetCost != null) {
      setErrorMessageActualCurrency('Please select currency')
      err += 'Please select currency'
    }
    if (strategicInitiative.value == '' || !strategicInitiative.value) {
      setErrorMessageStrategicInitiative('Strategic Initiative field cannot be empty');
      err += 'Strategic Initiative field cannot be empty'
    }
    if (err == '' || formStatusName == "Cancelled") {
      return true
    }
    else {
      return false;
    }
  }

  const handleSave = () => {

    if (requestType == "Update") {
      SendUpdateData('Save');
    } else {

      SendData('Save');
    }
  }
  /**
   * Saves the updated form values into database
   * @param {*} requestValue is used to differentiate between save and update form
   * @returns {Array}
   */
  function SendUpdateData(requestValue) {
    setSpinner(true);
    let request_id = createRequestData?.request_id ?? parseInt(window.location.pathname.split("/").pop(), 10);
    let data = {
      request_id: request_id,
      requester_name: userDetails.user_id,
      requested_for: requesterName,
      asset_id: assetUsage.value,
      is_budget_approve: budgetApproval.value,
      budget: budget,
      budget_currency: budgetCurrency.value,
      asset_use: assetUsage.value,
      indication_id: indication.map(x => Number(x.value)),
      brand_id: brandBugetTactic.map(x => Number(x.value)),
      country_id: country.value,
      category_id: [Number(category.value)],
      customer_segementation: customerSegmentaion.map(x => Number(x.value)),
      content_type: contentType.value,
      channel: channel.map(x => Number(x.value)),
      tactic_usability: assetTacticReuse.value,
      vvpm_material_number: vvpmMaterialNumber,
      tactic_type: tacticType.value,
      asset_language: assetLanguage.value,
      asset_objective: assetObjective.map(x => Number(x.value)),
      asset_due_date: assetDueDate,
      asset_actual_cost: actualAssetCost,
      asset_actual_cost_currency: actualAssetCurrency.value,
      keywords: searchKeyword,
      primary_category_id: primaryCategory.value,
      secondary_category_id: secondaryCategory.value,
      primary_sub_category_id: primarySubCategory.value,
      secondary_sub_category_id: secondarySubCategory.value,
      studies_included: studiesIncluded,
      strategic_initiative: strategicInitiative.value,
      speaker_name: speakerName,
      status: formStatus == 0 ? '1' : formStatus,
      updated_by: userDetails.user_id,
    }
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        updateRequest(response.idToken, data)
          .then(reqData => {
            if (reqData.statusCode == "200") {
              if (requestValue == "Save") {
                toast.success("Updated Successfully!!!");
                setSaveMessage(true);
                setTimeout(() => {
                  navigate("/");
                }, 1000);
              } else {
                setCreateRequestData(reqData.data)
                setVvpmModal(true);
              }
            } else {
              toast.error("Unable to save due to server error");
            }
            setSpinner(false);
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }

  const handleExit = () => {
    navigate("/");
  }
  /**
   * Saves the updated form values into database
   * Opens VVPM modal On click of Next button 
   * @returns {Array}
   */
  const handleNext = () => {
    let tempData = vendorList.filter(vndr => Number(vndr.country_id) === Number(country.value)).map((x) => ({
      label: x.vendor_name,
      value: x.vendor_id,
    
    }));

    console.log(tempData);
    tempData.push({label:'None of the Above', value:0})
    setVendorData(tempData,);
    if (["Draft", "New"].includes(formStatusName)) {
      let valid = handleValidate();
      if (valid) {
        if (requestType === "Update") {
          SendUpdateData('Next');
        } else {
          SendData('Next');
        }
      }
    } else {
      setVvpmModal(true);
    }
  };

  const showVVPMModal = () => {
    let tempData = vendorList.filter(vndr => Number(vndr.country_id) === Number(country.value)).map((x) => ({
      label: x.vendor_name,
      value: x.vendor_id,
    }));
    setVendorData(tempData);
    setVvpmModal(true);
  }

  const handleBudgetChange = (budget) => {
    setBudget(budget)
  }

  const syncData = (tempFormData) => {
    var doc = new jsPDF('p', 'pt', 'a4', true);
    let col = 60;
    let vvpm = 0;
    var imgLogo = new Image()
    imgLogo.src = logo
    doc.addImage(imgLogo, 'PNG', 230, 10, 80, 40)
    doc.setLineWidth(1.0);
    doc.setFont('PTSans'); // set custom font
    doc.setFontSize(14); // you can change size
    doc.line(10, 50, 600, 50);
    doc.text(20, 60, '')

    let tableData = [];

    for (let fdata in tempFormData) {
      if (!['status', 'brand_id', 'budget', 'vvpm_documents', 'asset_actual_cost_currency', 'tactic_type', 'strategic_initiative', 'primary_category_id', 'secondary_category_id', 'primary_sub_category_id', 'secondary_sub_category_id', 'country_id', 'created_by_name', 'updated_by_name', 'customer_segementation', 'category_id', 'indication_id', 'asset_use', 'requester_name', 'request_id', 'asset_id', 'budget_currency'].includes(fdata) && ![null, ''].includes(formDataById[fdata])) {

        if (typeof tempFormData[fdata] === "boolean") {
          tempFormData[fdata] = tempFormData[fdata] ? "Yes" : "No";
        }

        if (fdata === "content_type") {
          tempFormData[fdata] = tempFormData[fdata] ? contentTypeData.find(val => Number(val.value) === Number(tempFormData[fdata])).label : '';
        }

        if (fdata === "channel") {

          tempFormData[fdata] = tempFormData[fdata].length > 0 ? channelData.filter(val => tempFormData[fdata].includes(Number(val.value))).map(c => c.label).join(" , ") : '';
        }

        if (fdata === "asset_language") {
          tempFormData[fdata] = assetLanguageData.length > 0 ? assetLanguageData.find(val => Number(val.value) === Number(tempFormData[fdata])).label : '';
        }

        if (fdata === "tactic_usability") {
          tempFormData[fdata] = tempFormData[fdata] ? assetTacticReuseData.find(val => Number(val.value) === Number(tempFormData[fdata])).label : '';
        }

        if (fdata === "asset_objective") {
          tempFormData[fdata] = tempFormData[fdata].length > 0 ? assetObjectiveData.filter(val => tempFormData[fdata].includes(Number(val.value))).map(tc => tc.label).join(" , ") : '';
        }

        if (fdata === "brand") {
          tempFormData[fdata] = tempFormData['brand_id'].length > 0 ? tempFormData[fdata].data.filter(val => (tempFormData['brand_id']).includes(Number(val.brand_id))).map(c => c.brand_name).join(" , ") : '';
        }

        if (fdata === "budget") {
          tempFormData[fdata] = tempFormData['budget_currency'] !== null ? `${currencyTypeData.find(val => Number(val.value) === Number(tempFormData['budget_currency'])).label} ${tempFormData[fdata]}` : '';
        }

        if (fdata === 'vendor_id') {
          fdata = 'vendor';
          tempFormData[fdata] = ![0, undefined, ''].includes(tempFormData['vendor_id']) ? vendorList.find(vnd => Number(vnd.vendor_id) === Number(tempFormData['vendor_id'])).vendor_name : '';
        }

        if (fdata === "created_by") {
          tempFormData[fdata] = tempFormData['created_by_name'];
        }

        if (fdata === "updated_by") {
          tempFormData[fdata] = tempFormData['updated_by_name'];
        }

        if (fdata === "asset_due_date") {
          tempFormData[fdata] = moment(tempFormData[fdata]).format("L");
        }
        if (fdata === "createdAt") {
          fdata = 'Created At';
          tempFormData[fdata] = moment(tempFormData[fdata]).format("L");
        }
        if (fdata === "updatedAt") {
          fdata = 'Updated At';
          tempFormData[fdata] = moment(tempFormData[fdata]).format("L");
        }

        if (fdata === "indicationArray") {
          fdata = 'Indication';
          tempFormData[fdata] = tempFormData['indicationArray'].data.length > 0 ? tempFormData['indicationArray'].data.filter(val => tempFormData['indication_id'].includes(val.indication_id)).map(c => c.indication_name).join(" , ") : '';
        }
        delete tempFormData['asset_actual_cost'];
        delete tempFormData['asset_actual_cost_currency'];
        tableData.push([`${fdata.split('_').map(function (word) {
          return word[0].toUpperCase() + word.slice(1);
        }).join(" ")}`, ":", `${tempFormData[fdata]}`])
      }
    }
    autoTable(doc, {
      margin: { top: col },
      body: tableData
    })

    if (![null, '', undefined].includes(tempFormData['vvpm_documents'])) {
      let vvpmDocuments = [];

      tempFormData['vvpm_documents'] = JSON.parse(tempFormData['vvpm_documents'])

      vvpmDocuments = tempFormData['vvpm_documents'].map(vdoc => ([vdoc.document_number__v, vdoc.name__v, vdoc.status__v]))

      autoTable(doc, {
        margin: { top: vvpm },
        head: [['Document Number', 'Document Name', 'Status']],
        body: vvpmDocuments,
      })
    }

    doc.save(`${tempFormData['request_number']}.pdf`)
    setDownloadPdf(false)
  }

  // Download functionality of the briefing document
  const handleDownload = async () => {
    setDownloadPdf(true)
    let tempFormData = { ...formDataById }
    let pdfFormTempData = {};
    pdfFormTempData.request_number = tempFormData.request_number;
    pdfFormTempData.user_name = tempFormData.created_by_name;
    pdfFormTempData.country_name = tempFormData.country_name;
    pdfFormTempData.requested_for = tempFormData.requested_for;
    pdfFormTempData.is_budget_approve = tempFormData.is_budget_approve;
    pdfFormTempData.budget_currency = tempFormData.budget_currency;
    pdfFormTempData.budget = tempFormData.budget;
    pdfFormTempData.asset_use = tempFormData.asset_use;
    pdfFormTempData.indication_id = tempFormData.indication_id;
    pdfFormTempData.indicationArray = tempFormData.indicationArray;
    pdfFormTempData.brand_id = tempFormData.brand_id;
    pdfFormTempData.brand = tempFormData.brand;
    pdfFormTempData.vendor_id = tempFormData.vendor_id;
    pdfFormTempData.customer_segementation = tempFormData.customer_segementation;
    pdfFormTempData.content_type = tempFormData.content_type;
    pdfFormTempData.channel = tempFormData.channel;
    pdfFormTempData.tactic_usability = tempFormData.tactic_usability;
    pdfFormTempData.vvpm_material_number = tempFormData.vvpm_material_number;
    pdfFormTempData.tactic_type = tempFormData.tactic_type;
    pdfFormTempData.tactic_type_name = tempFormData.tactic_type_name;
    pdfFormTempData.asset_language = tempFormData.asset_language;
    pdfFormTempData.asset_due_date = tempFormData.asset_due_date;
    pdfFormTempData.asset_actual_cost_currency = tempFormData.asset_actual_cost_currency;
    pdfFormTempData.asset_actual_cost = tempFormData.asset_actual_cost;
    pdfFormTempData.primary_category_id = tempFormData.primary_category_id;
    pdfFormTempData.primary_sub_category_id = tempFormData.primary_sub_category_id;
    pdfFormTempData.secondary_category_id = tempFormData.secondary_category_id;
    pdfFormTempData.secondary_sub_category_id = tempFormData.secondary_sub_category_id;
    pdfFormTempData.studies_included = tempFormData.studies_included;
    pdfFormTempData.strategic_initiative = tempFormData.strategic_initiative;
    pdfFormTempData.speaker_name = tempFormData.speaker_name;
    pdfFormTempData.vvpm_documents = tempFormData.vvpm_documents;
    pdfFormTempData.status_name = tempFormData.status_name;
    pdfFormTempData.comment = tempFormData.comment;
    pdfFormTempData.created_by = tempFormData.created_by;
    pdfFormTempData.created_by_name = tempFormData.created_by_name;
    pdfFormTempData.updated_by = tempFormData.updated_by;
    pdfFormTempData.updated_by_name = tempFormData.updated_by_name;
    pdfFormTempData.createdAt = tempFormData.createdAt;
    pdfFormTempData.updatedAt = tempFormData.updatedAt;
    setTimeout(() => syncData(pdfFormTempData), 2000)
  };
  /**
   * Saves the updated form values into database and submits the form
   * @param {*} value is used to store VVPM docs into DB
   * @param {*} vendor is used to store vendor value into DB
   * @returns {Array}
   */
  const handleSubmitVVPM = (value, vendor, flag) => {
    console.log(value, vendor, flag)
    let vvpm_docs = JSON.stringify(value);
    let data = {
      request_id: createRequestData?.request_id ?? parseInt(window.location.pathname.split("/").pop(), 10),
      vvpm_documents: vvpm_docs,
      vendor_id: vendor?.value ? vendor.value : null,
      comment: '',
      vvpm_recommendations_generated : flag,
      status: 2
    }
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        updateGoNogoRequest(response.idToken, data)
          .then(response => {
            if (response) {
              if (response.statusCode == "200") {
                toast.success("Form Submitted Successfully!");
                setSubmitMessage(true)
                setTimeout(() => {
                  navigate("/");
                }, 1000);
              }
              else {
                toast.error("Unable to submit the form due to server error");
                setTimeout(() => {
                  navigate("/");
                }, 2000);
              }
            }
          }).catch(err => console.log(err))
      })
  };
  const handleNoGoVVPM = (value, vendor, flag) => {
    console.log('flag', value, vendor, flag)
    let vvpm_docs = JSON.stringify(value);
    setVvpmDocsNoGo(vvpm_docs);
    setVvpmNoGoFlag(flag)
    setVendorNoGo(vendor);
    setConfirmationModal(true);
    setNogoPopout(true);
    setVvpmModal(false);
  }

  const handleCloseVVPM = () => {
    setVvpmModal(false);
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
  };
  /**
   * Saves the updated form values into database and makes the form status as exception
   * @param {*} comment is used to store NO-GO comment into DB
   * @returns {Array}
   */
  const handleSubmitNoGo = (comment) => {
    let vendor = vendorNoGo?.value ? vendorNoGo.value : null
    let data = {
      request_id: createRequestData?.request_id ?? parseInt(window.location.pathname.split("/").pop(), 10),
      vvpm_documents: vvpmDocsNoGo,
      vendor_id: vendor,
      comment: comment,
      vvpm_recommendations_generated : vvpmNoGoFlag,
      status: 6
    }
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        updateGoNogoRequest(response.idToken, data)
          .then(response => {
            if (response) {
              if (response.statusCode === "200") {
                setExceptionMessage(true);
                toast.success("Comment Submitted Successfully!");
                setTimeout(() => {
                  navigate("/");
                }, 2000);
              }
              else {
                toast.error("Unable to submit the form due to server error");
                setVvpmModal(true)
                setConfirmationModal(false)
              }
            }
          }).catch(err => console.log(err))
      })
  };

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let userData = JSON.parse(localStorage.getItem('user'));
      setUserDetails(userData);
      setUserRole(roles[userData.role - 1]);
      setCurrentUser(userData);
    } else {
      RequestUserDetails();
    }
    
    RequestDropdownData();
    if (window.location.pathname.split("/").pop() !== 'form') {
      setSelectedForm(parseInt(window.location.pathname.split("/").pop(), 10));
      RequestFormDetails(parseInt(window.location.pathname.split("/").pop(), 10));
      setRequestType("Update");
    } else {
      let userData = JSON.parse(localStorage.getItem('user'));
      setUserDetails(userData);
      setUserName(userData.user_name);
      setUserId(userData.user_id);
      setRequestType("Add");
    }
  }, [])



  const handleContentChange = (event) => {
    setContentType({
      label: event.label,
      value: event?.value,
    })
    setBrandBudgetTactic([])
    if (event.label === 'Priority Brand') {
      let tempdata = [];
      tempbrandData.forEach(x => {
        if (x.label != 'Emgality') {
          tempdata.push(x)
        }
      })
      setBrandBudgetTacticDropdownData(tempdata)
    } else if (event.label === 'Non-Priority Brand') {
      let tempdata = [];
      tempbrandData.forEach(x => {
        if (x.label == 'Emgality') {
          tempdata.push(x)
        }
      })
      setBrandBudgetTacticDropdownData(tempdata)
    } else {
      setBrandBudgetTacticDropdownData([])
    }
  }
  //handle side effects of changing content type
  // useEffect(() => {
  //   // if (window.location.pathname.split("/").pop() === 'form') {
  //     console.log('content type', tempbrandData, contentType)
  //     setBrandBudgetTactic([])
  //     if (contentType.label === 'Priority Brand') {
  //       let tempdata = [];
  //       tempbrandData.forEach(x => {
  //         if (x.label != 'Emgality') {
  //           tempdata.push(x)
  //         }
  //       })
  //       setBrandBudgetTacticDropdownData(tempdata)
  //     } else if (contentType.label === 'Non-Priority Brand') {
  //       let tempdata = [];
  //       tempbrandData.forEach(x => {
  //         if (x.label == 'Emgality') {
  //           tempdata.push(x)
  //         }
  //       })
  //       setBrandBudgetTacticDropdownData(tempdata)
  //     } else {
  //       setBrandBudgetTacticDropdownData([])
  //     }
  //   // }
  // }, [contentType])


  return (
    <div className="intakeForm container">
      {spinner ? (
        <div className="spinner-border align-items-center justify-content-md-end spinner-styles"></div>
      ) : (
        <>
          <Breadcrumbs pages=
            {[{ label: "Home", path: "/" },
            { label: selectedForm ? "Update IIF" : "Add IIF", path: "/form" }]} />
          <h5>{selectedForm ? "Update IIF" : "Add IIF"}</h5>
          {selectedForm && (
            <p>Request Number: {requestNumber}</p>
          )}

          {saveMessage && (
            <div class="alert alert-success show" role="alert">
              <strong>Saved Successfully!!!</strong>
            </div>
          )}
          {cancelMessage && (
            <div class="alert alert-success show" role="alert">
              <strong>Cancelled Successfully!!!</strong>
            </div>
          )}

          <div className="intakeform">
            <div className="intakeform-element">
              <Input
                inputType="text"
                value={userName}
                label="Name"
                disabled={true}
                placeholder="Enter Name"
                required
                errorMessage={errorMessageName}

              />
            </div>
            <div className="intakeform-element">
              <Input
                inputType="text"
                value={formStatusName}
                label="Status"
                disabled={true}
                placeholder="Enter Status"
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={handleCountryChange}
                closeMenuOnSelect={true}
                value={country.value != '' ? country : null}
                label="Country the asset is requested for"
                required
                options={countryDropdownData}
                placeholder="Select Country"
                errorMessage={errorMessageCountry}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager" && currentUser.user_id == userId) || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <Input
                inputType="text"
                value={requesterName}
                label="Are you requesting on behalf of someone?"
                placeholder="Enter Requestor Name"
                onInputChange={(value) => setRequesterName(value)}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setCategory({
                    label: event.label,
                    value: event?.value,
                  })}
                closeMenuOnSelect={true}
                value={category.value != '' ? category : null}
                label="Category"
                required
                options={categoryDropdownData}
                placeholder="Select Category"
                errorMessage={errorMessageCategory}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setBudgetApproval({
                    label: event.label,
                    value: event.value,
                  })}
                closeMenuOnSelect={true}
                value={budgetApproval.value != '' ? budgetApproval : null}
                label="Is the budget approved to develop the asset?"
                required
                options={budgetApprovalDropdownData}
                placeholder="Select if budget is approved?"
                errorMessage={errorMessageBudgetApproval}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <label>Planned Budget</label>
              <span className="mandatory">*</span>
              <div className="currency-element">
                <FormSelect
                  onChange={
                    (event) => setBudgetCurrency({
                      label: event.label,
                      value: event?.value,
                    })}
                  closeMenuOnSelect={true}
                  value={budgetCurrency.value != '' ? budgetCurrency : null}
                  options={budgetCurrencyDropdownData}
                  placeholder="Select Currency"
                  errorMessage={errorMessageBudgetCurrency}
                  disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                    && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
                />
                <Input
                  inputType="number"
                  value={budget}
                  placeholder="Enter Planned Budget"
                  onInputChange={handleBudgetChange}
                  errorMessage={errorMessageBudget}
                  disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                    && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
                />
              </div>
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setAssetUsage({
                    label: event.label,
                    value: event?.value,
                  })}
                closeMenuOnSelect={true}
                value={assetUsage.value != '' ? assetUsage : null}
                label="Where is the asset going to be used?"
                required
                options={assetUsageDropdownData}
                placeholder="Select Asset Usage"
                errorMessage={errorMessageAssetUsage}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={handleIndication}
                closeMenuOnSelect={false}
                components={animatedComponents}
                label="Indication"
                options={indicationDropdownData}
                ismulti
                value={indication.length > 0 ? indication : []}
                placeholder="Select Indication"
                errorMessage={errorMessageIndication}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={handleCustomerSegmentation}
                closeMenuOnSelect={false}
                components={animatedComponents}
                label="Customer Segmentation"
                options={customerSegemntationDropdownData}
                ismulti
                value={customerSegmentaion.length > 0 ? customerSegmentaion : []}
                placeholder="Select Customer Segmentation"
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            
            <div className="intakeform-element">
              <FormSelect
                onChange={(event) => handleContentChange(event)}
                // onChange={(event) => setContentType({
                //     label: event.label,
                //     value: event?.value,
                //   })}
                closeMenuOnSelect={true}
                value={contentType.value != '' ? contentType : null}
                label="Content Type"
                required
                options={contentTypeDropdownData}
                placeholder="Select Content Type"
                errorMessage={errorMessageContentType}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={handleBrandBudgetTactic}
                closeMenuOnSelect={false}
                components={animatedComponents}
                label="Which brand budget is the tactic approved"
                required
                options={brandBudgetTacticDropdownData}
                ismulti
                value={brandBugetTactic.length > 0 ? brandBugetTactic : []}
                placeholder="Select Brand Budget Tactic"
                errorMessage={errorMessageBrandBudgetTactic}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={handleChannel}
                closeMenuOnSelect={false}
                components={animatedComponents}
                label="Channel(s) the asset is produced for/Tactic type"
                required
                options={channelDropdownData}
                ismulti
                value={channel.length > 0 ? channel : []}
                placeholder="Select Channel"
                errorMessage={errorMessageChannel}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            
            
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setAssetTacticReuse({
                    label: event.label,
                    value: event?.value,
                  })}
                closeMenuOnSelect={true}
                value={assetTacticReuse.value != '' ? assetTacticReuse : null}
                label="Is the tactic a new development or you want to re-use"
                options={assetTacticReuseDropdownData}
                placeholder="Select Tactic"
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <Input
                inputType="text"
                value={vvpmMaterialNumber}
                label="VVPM Material Number (if re-use)"
                placeholder="Enter VVPM Material Number"
                onInputChange={(value) => setVVPMMaterialNumber(value)}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || assetTacticReuse.value == '1' || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}

              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setTacticType({
                    label: event.label,
                    value: event?.value,
                  })}
                closeMenuOnSelect={true}
                value={tacticType.value ? tacticType : null}
                label="Type of asset/Tactic Type"
                required
                options={tacticTypeDropdownData}
                placeholder="Select Tactic Type"
                errorMessage={errorMessageTacticType}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setAssetLanguage({
                    label: event.label,
                    value: event?.value,
                  })}
                closeMenuOnSelect={true}
                value={assetLanguage.value != '' ? assetLanguage : null}
                label="Language of the Asset"
                required
                options={assetLanguageDropdownData}
                placeholder="Select Asset Language"
                errorMessage={errorMessageAssetLanguage}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={handleAssetObjective}
                closeMenuOnSelect={false}
                components={animatedComponents}
                label="What is the Objective of the asset and expected impact?"
                options={assetObjectiveDropdownData}
                ismulti
                value={assetObjective.length > 0 ? assetObjective : []}
                placeholder="Select Objective of the asset and expected impact?"
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormDatePicker
                label="Due date for the asset"
                selectedDate={assetDueDate}
                onDateChange={handleAssetDueDate}
                placeholder="Select Date"
                required
                errorMessage={errorMessageAssetDueDate}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <label>Actual Budget</label>
              <div className="currency-element">
                <FormSelect
                  onChange={
                    (event) => setActualAssetCurrency({
                      label: event.label,
                      value: event?.value,
                    })}
                  closeMenuOnSelect={true}
                  value={actualAssetCurrency.value != '' ? actualAssetCurrency : null}
                  options={actucalCurrencyDropdownData}
                  placeholder="Select Currency"
                  errorMessage={errorMessageActualCurrency}
                  disabled={(userRole == "Admin") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (userRole == "CMO") ? true : (userRole == "Brand Manager"
                    && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId)}
                />
                <Input
                  inputType="text"
                  value={actualAssetCost}
                  placeholder="Enter Actual Budget"
                  onInputChange={(value) => setActualAssetCost(value)}
                  errorMessage={errorMessageActualCost}
                  disabled={(userRole == "Admin") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (userRole == "CMO") ? true : (userRole == "Brand Manager"
                    && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId)}
                />
              </div>
            </div>
            <div className="intakeform-element">
              <Input
                inputType="text"
                value={searchKeyword}
                label="Free text for search"
                placeholder="Search Keyword"
                onInputChange={(value) => setSearchKeyword(value)}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setPrimaryCategory({
                    label: event.label,
                    value: event?.value,
                    vvpm_id: event?.vvpm_id
                  })}
                closeMenuOnSelect={true}
                value={primaryCategory.value ? primaryCategory : null}
                label="Primary Category"
                options={primaryCategoryDropdownData}
                placeholder="Select Primary Category"
                errorMessage={errorMessagePrimaryCategory}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
              
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setPrimarySubCategory({
                    label: event.label,
                    value: event?.value,
                  })}
                closeMenuOnSelect={true}
                value={primarySubCategory.value ? primarySubCategory : null}
                label="Primary Sub Category"
                options={primarySubCategoryDropdownData}
                placeholder="Select Primary Sub Category"
                errorMessage={errorMessagePrimarySubCategory}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager" && currentUser.user_id == userId) ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setSecondaryCategory({
                    label: event.label,
                    value: event?.value,
                    vvpm_id: event?.vvpm_id
                  })}
                closeMenuOnSelect={true}
                value={secondaryCategory.value ? secondaryCategory : null}
                label="Secondary Category"
                options={secondaryCategoryDropdownData}
                placeholder="Select Secondary Category"
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager" && currentUser.user_id == userId) ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setSecondarySubCategory({
                    label: event.label,
                    value: event?.value,
                  })}
                closeMenuOnSelect={true}
                value={secondarySubCategory.value ? secondarySubCategory : null}
                label="Secondary Sub Category"
                options={secondarySubCategoryDropdownData}
                placeholder="Select Secondary Sub Category"
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager" && currentUser.user_id == userId) ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <Input
                inputType="text"
                value={studiesIncluded}
                label="Studies Included"
                placeholder="Enter Studies Included"
                onInputChange={(value) => setStudiesInclided(value)}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <FormSelect
                onChange={
                  (event) => setStrageticInitiative({
                    label: event.label,
                    value: event?.value,
                  })}
                closeMenuOnSelect={true}
                value={strategicInitiative.value ? strategicInitiative : null}
                label="Strategic Initiative"
                required
                options={strategicInitiativeDropdownData}
                placeholder="Select Strategic Initiative"
                errorMessage={errorMessageStrategicInitiative}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
            <div className="intakeform-element">
              <Input
                inputType="text"
                value={speakerName}
                label="Speaker Name"
                placeholder="Enter Speaker Name"
                onInputChange={(value) => setSpeakerName(value)}
                disabled={(userRole == "Admin" || (userRole == "CMO") || (formStatusName == "Exception" && userRole == "Brand Manager") || (formStatusName == "Cancelled" && userRole == "Brand Manager") || (formStatusName == "Submitted" && userRole == "Brand Manager") ? true : (userRole == "Brand Manager"
                  && selectedForm == "Update IIF" && formStatusName == "Draft" && currentUser.user_id == userId))}
              />
            </div>
          </div>
          <br></br>

          <div className="form-submit">
            <Button
              variant="outline-primary"
              onClick={() => handleExit()}
            >
              Exit
            </Button>
            {userRole == "Brand Manager" && formStatusName != "Cancelled" && (
              <Button
                variant="outline-primary"
                onClick={() => handleSave()}
                className="button-element"
              >
                Save
              </Button>
            )}

            {currentUser.role === 2 && formStatusName === "Draft" && userRole === "Brand Manager" && (
              <Button
                variant="outline-primary"
                onClick={() => handleCancelRequest()}
                className="button-element"
              >
                Cancel
              </Button>
            )}

            {userRole === "Admin" ? (
              <Button
                variant="outline-primary"
                onClick={() => showVVPMModal()}
                className="button-element"
              >
                Next
              </Button>
            ) : (
              <Button
                variant="outline-primary"
                onClick={() => handleNext()}
                className="button-element"
              >
                Next
              </Button>
            )}

            {userRole === "Brand Manager" && (formStatusName === "Submitted" || formStatusName === "Exception")  && !downloadpdf ? (
              <Button
                variant="outline-primary"
                onClick={() => handleDownload()}
                className="button-element"
              >
                Briefing Document
              </Button>

            ) : <>

            {userRole === "Brand Manager" && (formStatusName === "Submitted" || formStatusName === "Exception") &&
             <Button variant="outline-primary" className="button-element" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{' '}
              Downloading...
            </Button>}</>}

          </div>
          <ToastContainer />
          {vvpmModal ?
            <VVPMModal
              title={'VVPM Recommendations'}
              handleSubmit={(value, vendor, flag) => handleSubmitVVPM(value, vendor, flag)}
              handleClose={() => handleCloseVVPM()}
              handleNoGo={(value, vendor, flag) => handleNoGoVVPM(value, vendor, flag)}
              keyword={searchKeyword}
              speakerName={speakerName}
              studiesIncluded={studiesIncluded}
              indication={indication}
              userRole={userRole}
              selectedVendor={selectedVendor}
              selectedBrandVVPMID={selectedBrandVVPMID}
              formStatusName={formStatusName}
              createRequestData={createRequestData}
              countryResponse={countryResponse}
              currentUser={currentUser}
              brandList={brandList}
              vendorData={vendorData}
              country={country}
              country_v={country_v}
              isEwizard={isEwizard}
              primaryCategory={primaryCategory}
              primarySubCategory={primarySubCategory}
              secondaryCategory={secondaryCategory}
              secondarySubCategory={secondarySubCategory}
              brandBugetTactic={brandBugetTactic}
              vvpmDocs={vvpmDocs.length > 0 ? vvpmDocs : []}
              submitMessage={submitMessage}
              givenComment={givenComment}
              contentType = {contentType}
            /> : null}
          {confirmationModal ?
            <Modal
              title={'Are you sure you want to cancel the form ?'}
              handleClose={() => handleCloseModal()}
              handleCancel={() => handleCancel()}
              nogoPopout={nogoPopout}
              handleSubmitNoGo={handleSubmitNoGo}
              exceptionMessage={exceptionMessage}
            /> : null}
        </>
      )}
    </div>
  );
};
export default IntakeForm;