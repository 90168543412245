/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import InputField from "./Form/Input";
import FormSelect from "./Form/Select";
import Form from 'react-bootstrap/Form';
import { toast } from "react-toastify";
import { useMsal } from '@azure/msal-react';
import { getVVPMDocumentsByFilters, getAllCountriesData } from '../constants/apiCalls';
import { loginRequest } from '../constants/authConfig';
import "../styles/modal.css";
const VVPMModal = ({ title, handleSubmit, handleClose, keyword, vvpmDocs,
    speakerName, studiesIncluded, indication, primaryCategory, primarySubCategory, secondaryCategory, secondarySubCategory,
    userRole, selectedVendor, brandBugetTactic, country, handleNoGo, vendorData, currentUser, isEwizard, formStatusName,
    submitMessage, givenComment, countryResponse, country_v, selectedBrandVVPMID, brandList, contentType }) => {

    const { instance, accounts } = useMsal();
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [vendor, setVendor] = useState();
    const [vvpmData, setVvpmData] = useState([]);
    const [vvpmResult, setVvpmResult] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filterkeyword, setFilterKeyword] = useState();
    const [filteredArray, setFilteredArray] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [vvpmResults, setVvpmResults] = useState([]);
    const [isVvpmData, setIsVvpmData] = useState(true);
    const [checkPriorityBrand, setCheckPriorityBrand] = useState('');
    const [errorVendorPartner, setErrorVendorPartner] = useState('');
    const [checkNonPriorityBrand, setCheckNonPriorityBrand] = useState('');
    const [message, setMessage] = useState('');
    const [vvpmFlag, setVvpmFlag] = useState('No')

    const indicationData = (indication.map(x => {
        return x.label;
    }));
    const brandData = (brandBugetTactic.map(x => {
        return x.label;
    }));


    useEffect(() => {
        (brandList.filter(v => brandBugetTactic.map(b => b.value).includes(v.brand_id)).map(xB => {

            if (xB.priority) {
                setCheckPriorityBrand('Yes');
            } else {
                setCheckNonPriorityBrand('Yes');
            }
        }));

        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {

                if (vvpmDocs.length > 0) {
                    setVvpmData(vvpmDocs)
                    let tempvendor = vendorData.find(vndr => vndr.value === selectedVendor);
                    console.log('tempvendor',tempvendor)
                    setVendor(tempvendor);
                    setIsVvpmData(false)
                } else {
                    if (['Draft', 'New'].includes(formStatusName) && userRole === 'Brand Manager') {
                        let mainArray = [];
                        if (brandBugetTactic) {
                            for (const singleBrand of brandBugetTactic) {
                                mainArray.push(singleBrand.vvpm_id);
                            }
                        }
                        let mainIndArray = [];
                        if (indication) {
                            for (const singleIndication of indication) {
                                mainIndArray.push(singleIndication.vvpm_id);
                            }
                        }
                        let filter = {
                            'brand': mainArray, //[]
                            'primary_category__c': primaryCategory.vvpm_id, //VVPM Id
                            'secondary_category__c': secondaryCategory.vvpm_id, //VVPM Id
                            'indication2__c': mainIndArray //[A01000000000301, A01000000000302]
                        };
                        getVVPMDocumentsByFilters(response.idToken, filter)
                            .then(response => {
                                if (response) {
                                    if (response.statusCode === "200") {
                                        handleVVPMResults(response.data);
                                        if(response.data.length > 0) {
                                            setVvpmFlag('Yes')
                                        }else{
                                            setVvpmFlag('No')
                                        }
                                        setMessage("No matching tactics could be found in VVPM for your Search");
                                    } else {
                                        toast.error("Unable to fetch VVPM Data due to server error");
                                        setVvpmFlag('Server Error');
                                        setMessage("Unable to fetch VVPM Data due to server error")
                                        setIsVvpmData(false)
                                    }
                                } else {
                                    toast.error("Unable to fetch VVPM Data due to server error");
                                    setVvpmFlag('Server Error');
                                    setMessage("Unable to fetch VVPM Data due to server error")
                                    setIsVvpmData(false)
                                }
                            }).catch(err => console.log(err))
                    } else {
                        if (vendorData) {
                            let tempvendor = vendorData?.find(vndr => vndr.value === selectedVendor);
                            setVendor(tempvendor);
                        } else {
                            setVendor('');
                        }
                        setIsVvpmData(false);
                        setMessage("No VVPM selected.");
                    }
                }
            })
    }, [])


    useEffect(() => {
        if (userRole == "Admin") {
            setColumns([
                { key: 'document_number__v', label: 'Document Id' },
                { key: 'name__v', label: 'Document Name' },
            ])
        }

        if (userRole == "Brand Manager" || userRole == "CMO") {
            setColumns([
                { key: 'document_number__v', label: 'Document Id' },
                { key: 'name__v', label: 'Document Name' },
                { key: 'checked', label: 'Select' }
            ])
        }
    }, [])


    const handleSelect = (data) => {
        // find index of data in VVPM list
        let vvpmIndex = vvpmData.findIndex(x => x.id === data.id);
        // find index in selected documents
        let selectedDocumentsIndex = selectedDocuments.findIndex(y => y.id === data.id);
        // check the data in selected documents
        if (selectedDocumentsIndex !== -1) {
            // if data is available selected documents then remove it 
            let tempSelectedDocuments = selectedDocuments;
            tempSelectedDocuments.splice(selectedDocumentsIndex, 1);
            setSelectedDocuments(tempSelectedDocuments);
            //  and uncheck from vvpm list
            let tempVvpmData = [...vvpmData];
            tempVvpmData[vvpmIndex].checked = false;
            setVvpmData(tempVvpmData);
        }
        // if data is not in selected documents
        else {
            // add data to selected documents
            let tempSelectedDocuments = [...selectedDocuments, data];

            setSelectedDocuments(tempSelectedDocuments);
            // check in vvpm list
            let tempVvpmData = [...vvpmData];
            tempVvpmData[vvpmIndex].checked = true;
            setVvpmData(tempVvpmData);
        }
    }
    const handleVVPMResults = (vvpmResult) => {

        let list = vvpmResult.map(i => {
            if (selectedDocuments.findIndex(x => x.document_id === i.document_id) > -1) {
                return ({ ...i, 'checked': true })
            } else {
                return ({ ...i, 'checked': false });
            }
        });
        setVvpmData(list);
        setIsVvpmData(false)
    }
    const handleClick = (selectedDocuments,vendor, vvpmData, vvpmFlagrec) =>{
        console.log(selectedDocuments,vendor, vvpmData, contentType.label, vvpmFlagrec)
        if (vendor !== undefined) {
            contentType.label == 'Priority Brand' && vendor.label != 'None of the Above' ? handleSubmit(selectedDocuments, vendor, vvpmFlagrec) : handleNoGo(selectedDocuments, vendor, vvpmFlagrec)
        } else {
            setErrorVendorPartner('Vendor partner cannot be empty')
        }
    } 
    const handleCloseSelect = (data) => {
        // find index of data in VVPM list
        let vvpmIndex = vvpmData.findIndex(x => x.document_id === data.document_id);
        // find index in selected documents
        let selectedDocumentsIndex = selectedDocuments.findIndex(y => y.document_id === data.document_id);
        // Data is available selected documents then remove it 
        let tempSelectedDocuments = selectedDocuments;
        tempSelectedDocuments.splice(selectedDocumentsIndex, 1);
        setSelectedDocuments(tempSelectedDocuments);
        //  and uncheck from vvpm list
        let tempVvpmData = [...vvpmData];
        tempVvpmData[vvpmIndex].checked = false;
        setVvpmData(tempVvpmData);
    }

    const searchKeyword = (value) => {
        setFilterKeyword(value);
        if (filterkeyword !== '') {
            let tempVvpmData = [...vvpmData];
            tempVvpmData = tempVvpmData.filter(val => val.name__v.toLowerCase().includes(value.toLowerCase()));
            setFilteredArray(tempVvpmData);
        } else {
            setFilteredArray(vvpmData)
        }
    }

    return (
        <div className="modal">
            {spinner ? (
                <div className="spinner-border align-items-center justify-content-md-end spinner-styles"></div>
            ) : (
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 style={{ fontSize: '1.5rem' }}>{title}</h3>

                        <p onClick={() => handleClose(false)} className="display-6 lead"> x </p>
                    </div>
                    {submitMessage && (
                        <div class="alert alert-success show" role="alert">
                            <strong>Submitted Successfully!!!</strong>
                        </div>
                    )}
                    {isEwizard === true && currentUser.role === 2 && <div className="header-message">Please use E-Wizard if your Tactic type is E-mail since your country is authorized to use E-Wizard.</div>}
                    <div className="modal-body">
                        {/* create a curated body to search vvpm attachments */}
                        <div className='vvpm-container'>
                            <div>
                                <div className='vvpm-search text-muted'>
                                    <FormSelect
                                        closeMenuOnSelect={true}
                                        value={vendor}
                                        required={true}
                                        label="Vendor Partner List"
                                        options={vendorData}
                                        disabled={currentUser.role !== 2 || !['New', 'Draft'].includes(formStatusName) ? true : false}
                                        placeholder="Select Vendor"
                                        onChange={
                                            (event) => setVendor({
                                                label: event.label,
                                                value: event.value,
                                            })}
                                        errorMessage={errorVendorPartner}
                                    />
                                </div>

                                <div className="search_parameter">
                                    {currentUser.role === 2 && <p className="text-muted">Search Parameters</p>}
                                    {keyword && <InputField
                                        label="Search Keyword"
                                        type="text"
                                        value={keyword}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                    {speakerName && <InputField
                                        label="Speaker name"
                                        type="text"
                                        value={speakerName}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                    {studiesIncluded && <InputField
                                        label="Studies Included"
                                        type="text"
                                        value={studiesIncluded}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                    {Array.isArray(indicationData) && indicationData.length > 0 && <InputField
                                        label="Indication"
                                        type="text"
                                        value={indicationData}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                    {Array.isArray(brandData) && brandData.length > 0 && <InputField
                                        label="Brand"
                                        type="text"
                                        value={brandData}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                    {primaryCategory.label && <InputField
                                        label="Primary Category"
                                        type="text"
                                        value={primaryCategory.label}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                    {primarySubCategory.label && <InputField
                                        label="Primary sub-category"
                                        type="text"
                                        value={primarySubCategory.label}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                    {secondaryCategory.label && <InputField
                                        label="Secondary Category"
                                        type="text"
                                        value={secondaryCategory.label}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                    {secondarySubCategory.label && <InputField
                                        label="Secondary sub-category"
                                        type="text"
                                        value={secondarySubCategory.label}
                                        name="textBox"
                                        className="form-control mb-1"
                                        disabled
                                    />}
                                </div>

                            </div>
                            <div className="vvpmdata">
                                {vvpmData && vvpmData.length > 0 && (
                                    <div className="vvpmkeyword">
                                        <p className="text-muted lead" style={{ display: 'none' }}> Keyword </p>
                                        <input
                                            type="text"
                                            defaultValue={filterkeyword}
                                            name="textBox"
                                            className="form-control mb-1 search_keyword_width"
                                            placeholder="search by document name..."
                                            onChange={(event)=>searchKeyword(event.target.value)}
                                        />

                                    </div>)}
                                <div className="vvpm-selected-v1">{currentUser.role === 2 && selectedDocuments.length > 0 && selectedDocuments.map((data) =>
                                    <div key={`default-${data.name__v}`} className="mb-3 form-check-1">
                                        <Form.Check
                                            type="checkbox"
                                            id={`default-checkbox`}
                                            label={`${data.name__v}`}
                                        />
                                    </div>
                                )}</div>
                                <div className="vvpmtable">

                                    <table className="table table-striped rounded">
                                        <thead className="sticky-top">
                                            <tr>
                                                {columns.map(c => (
                                                    <th className="table-head-th">{c.label}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>{isVvpmData ? <div className="spinner-border align-items-center justify-content-md-end spinner-styles"></div> :
                                                <>{vvpmData && vvpmData.length > 0 ?
                                                    filterkeyword ? filteredArray.map(data => {
                                                        return (
                                                            <tr>
                                                                {columns.map(({ key }) => {
                                                                    if (key !== 'checked') {
                                                                        return <td>
                                                                            {data[key]}
                                                                        </td>
                                                                    } else {
                                                                        return <td>
                                                                            {[2].includes(currentUser.role) && (formStatusName === 'Draft' || formStatusName === 'New') ? <button
                                                                                className={data['checked'] ?
                                                                                    "btn btn-success btn-sm" :
                                                                                    "btn btn-outline-danger btn-sm"}
                                                                                onClick={() => handleSelect(data)}
                                                                            >
                                                                                {data['checked'] ? 'Yes' : 'No'}
                                                                            </button> : <button
                                                                                className={data['checked'] ?
                                                                                    "btn btn-success btn-sm" :
                                                                                    "btn btn-outline-danger btn-sm"}

                                                                            >
                                                                                {data['checked'] ? 'Yes' : 'No'}
                                                                            </button>}
                                                                        </td>
                                                                    }
                                                                })}
                                                            </tr>
                                                        )
                                                    }) : vvpmData.map(data => {
                                                        return (
                                                            <tr>
                                                                {columns.map(({ key }) => {
                                                                    if (key !== 'checked') {
                                                                        return <td>
                                                                            {data[key]}
                                                                        </td>
                                                                    } else {
                                                                        return <td>
                                                                            {[2].includes(currentUser.role) && (formStatusName === 'Draft' || formStatusName === 'New') ? <button
                                                                                className={data['checked'] ?
                                                                                    "btn btn-success btn-sm" :
                                                                                    "btn btn-outline-danger btn-sm"}
                                                                                onClick={() => handleSelect(data)}
                                                                            >
                                                                                {data['checked'] ? 'Yes' : 'No'}
                                                                            </button> : <button
                                                                                className={data['checked'] ?
                                                                                    "btn btn-success btn-sm" :
                                                                                    "btn btn-outline-danger btn-sm"}
                                                                            >
                                                                                {data['checked'] ? 'Yes' : 'No'}
                                                                            </button>}
                                                                        </td>
                                                                    }
                                                                })}
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td colSpan={columns.length} className="text-center">
                                                            {message}
                                                        </td>
                                                    </tr>}</>}</>
                                        </tbody>
                                    </table>

                                    {userRole == "Brand Manager" && checkPriorityBrand === 'Yes' && vvpmData.length === 0 && !isVvpmData && (
                                        <div className="rejection-comments">
                                            <h6>No VVPM recommendations are available for your search and hence New Content Creation is authorized as the content belongs to a priority brand.</h6>
                                        </div>
                                    )}
                                    {userRole == "Brand Manager" && checkNonPriorityBrand === 'Yes' && vvpmData.length === 0 && !isVvpmData && (
                                        <div className="rejection-comments">
                                            <h6>No VVPM recommendations are available for your search and New Content Creation is not allowed as the content belongs to a non-priority brand.</h6>
                                        </div>
                                    )}
                                </div>
                                {formStatusName === 'Exception' && (
                                    <div className="no_go_comment">
                                        <label>No GO Comment</label>
                                        <textarea disabled value={givenComment} className="form-control mb-1 mt-3">
                                        </textarea>
                                    </div>
                                )}

                            </div>
                        </div>

                    </div>
                    <div className="modal-footer text-center">

                        {/* {[2].includes(currentUser.role) && (formStatusName === 'Draft' || formStatusName === 'New') && (
                            <button className="btn btn-outline-primary" onClick={() => handleSubmit(selectedDocuments, vendor)}>Go</button>
                        )}
                        {[2].includes(currentUser.role) && (formStatusName === 'Draft' || formStatusName === 'New') && (
                            <button className="btn btn-outline-danger" onClick={() => handleNoGo(selectedDocuments, vendor)}>No Go</button>
                        )} */}
                        {[2].includes(currentUser.role) && (formStatusName === 'Draft' || formStatusName === 'New') && (
                            <button className="btn btn-outline-primary" onClick={() => handleClick(selectedDocuments, vendor, vvpmData, vvpmFlag)}>Submit</button>
                        )}
                        <button className="btn btn-outline-dark" onClick={() => handleClose()}>Close</button>
                    </div>
                </div>
            )}
        </div>
    )
};

export default VVPMModal;
